import React from 'react'
import { withStyles, Box, Typography, Grid, Button } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { privateNewNedgiaAppGuidePanelStyles } from './private-new-nedgia-app-panel.style'
import { TABLET_IMG, privateAreaGuideArray } from '../../constants/new-nedgia-app.constants'

export const NewNedgiaAppGuidePanel = withStyles(privateNewNedgiaAppGuidePanelStyles)(
  ({ classes }) => {
    const intl = useIntl()

    const openLinkHandler = (link) => {
      window.open(link)
    }

    const handlePrintList = () =>
      privateAreaGuideArray.map((item, i) => {
        return (
          <Grid key={i} item lg={4} className={classes.guideItem}>
            <Box display="flex" flexDirection="column" mb={2}>
              <Typography style={{ fontWeight: 'bold' }} variant="h4" color="primary">
                {intl.formatMessage({ id: `pages.privateAreaNavigationGuide.${item.title}` })}
              </Typography>
              <Typography style={{ fontSize: '1.5em' }} color="primary">
                {intl.formatMessage({
                  id: `pages.privateAreaNavigationGuide.description.${item.description}`,
                })}
              </Typography>
            </Box>
            <Box width="100%">
              <Button
                onClick={() => openLinkHandler(item.url)}
                color="primary"
                style={{ fontSize: '1.2em', padding: '0em 2em', marginBottom: '1em' }}
                variant="contained"
              >
                {intl.formatMessage({ id: 'pages.newApps.go' })}
              </Button>
              <Box className={classes.guideItemSeparator}></Box>
            </Box>
          </Grid>
        )
      })

    return (
      <Box
        display="flex"
        bgcolor="rgb(255,255,255)"
        flexDirection="column"
        alignItems="center"
        borderRadius={20}
        mt={2}
        mb={2}
        style={{ padding: '4em 5em' }}
      >
        <Box mt={4} mb={4} alignSelf="start">
          <Typography variant="h3" color="primary" style={{ fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'pages.newApps.title' })}
          </Typography>
        </Box>
        <Grid spacing={5} container alignItems="flex-start">
          {handlePrintList()}
        </Grid>
      </Box>
    )
  }
)
