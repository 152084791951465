import TABLET_IMG from '../../../resources/images/privateAreaNavigationPage/tablet.jpg'

export { TABLET_IMG }

export const privateAreaGuideArray = [
  {
    title: 'geoVisor',
    description: 'geoVisor',
    url: 'https://d1ytqaxjib7kk4.cloudfront.net/#/Login',
  },
  {
    title: 'HSE',
    description: 'HSE',
    url: 'https://www.hse.nedgia.es',
  },
  {
    title: 'Acces',
    description: 'Acces',
    url: 'https://www.nedgia.es/colaboradores/formulario-solicitud-acceso-a-sistemas-nedgia/',
  },
]
