import { useCallback, useReducer } from 'react'

import { publicOfferReducer } from '../reducers'
import { usePublicOfferCalls } from '../hooks'
import * as publicOffersActions from '../constants'
import { compareArrays } from '../utils/po.utils'

export const usePublicOfferReducer = () => {
  const [state, dispatch] = useReducer(publicOfferReducer, {
    publicOffer: [],
    generalConditions: false,
    publicOffersToSend: [],
    isLoading: true,
  })
  const { getPublicOfferData } = usePublicOfferCalls()

  const publicOfferData = useCallback((companyId, combos) => {
    getPublicOfferData(companyId)
      .then((data) => {
        const generalConditionsDocumentId = data.condiciones_generales_op_id
        const generalConditions = data.acepta_condiciones_generales_op
        let resumeFields = []
        let poSingleMarketsKeys = []
        let poComboMarketsKeys = []

        data.listado_campanas.forEach((campaing) => {
          if (campaing.estado !== null) {
            resumeFields.push(campaing)
          }
        })

        data.listado_campanas.forEach((campaing) => {
          if (campaing.mercados.length === 1) {
            if (
              campaing.mercados[0].nombre &&
              !poSingleMarketsKeys.some(
                (singleMarketKey) => singleMarketKey === campaing.mercados[0].nombre
              )
            ) {
              poSingleMarketsKeys.push(campaing.mercados[0].nombre)
            }
          } else {
            if (campaing.mercados) {
              let comboOption = []
              campaing.mercados.forEach((mercado) => {
                comboOption.push(mercado.nombre)
              })
              if (poComboMarketsKeys.length === 0) {
                poComboMarketsKeys.push(comboOption)
              } else {
                if (
                  !poComboMarketsKeys.some((combo) => {
                    return compareArrays(comboOption, combo)
                  })
                ) {
                  poComboMarketsKeys.push(comboOption)
                }
              }
            }
          }
        })
        let campaingsOnHisMarkets = []
        let comboKeysValues = []
        poSingleMarketsKeys.forEach((key) => {
          campaingsOnHisMarkets.push({
            value: combos.tipo_mercado_adhesiones?.data.find((el) => el.key === key).value,
            campañas: { instalador: [], cliente: [] },
            key: key,
          })
        })
        poComboMarketsKeys.forEach((comboKeys) => {
          let singleComboOption = []
          comboKeys.map((ck) => {
            return combos.tipo_mercado_adhesiones.data.forEach((tm) => {
              if (tm.key === ck) {
                singleComboOption.push(tm.value)
              }
            })
          })
          comboKeysValues.push(singleComboOption)
        })
        poComboMarketsKeys.forEach((comboKey, i) => {
          campaingsOnHisMarkets.push({
            value: comboKeysValues[i].join(' | '),
            campañas: { instalador: [], cliente: [] },
            key: comboKey,
          })
        })
        data.listado_campanas.forEach((campaña) => {
          campaingsOnHisMarkets.forEach((comboCampaing) => {
            if (campaña.mercados.length === 1) {
              if (comboCampaing.key === campaña.mercados[0].nombre) {
                if (campaña.is_op && campaña.origen === 'cliente') {
                  comboCampaing.campañas.cliente.unshift(campaña)
                } else if (
                  campaña.is_op &&
                  (campaña.origen === 'instalador' || campaña.origen === 'asociacion')
                ) {
                  comboCampaing.campañas.instalador.unshift(campaña)
                } else if (!campaña.is_op && campaña.origen === 'cliente') {
                  comboCampaing.campañas.cliente.push(campaña)
                } else if (
                  !campaña.is_op &&
                  (campaña.origen === 'instalador' || campaña.origen === 'asociacion')
                ) {
                  comboCampaing.campañas.instalador.push(campaña)
                }
              }
            }
            if (campaña.mercados.length >= 2) {
              let arrayCampañas = []
              campaña.mercados.map((mercado, i) => {
                return arrayCampañas.push(mercado.nombre)
              })

              if (comboCampaing.key instanceof Array) {
                if (compareArrays(arrayCampañas, comboCampaing.key)) {
                  if (campaña.is_op && campaña.origen === 'cliente') {
                    comboCampaing.campañas.cliente.unshift(campaña)
                  } else if (campaña.is_op && campaña.origen === 'instalador') {
                    comboCampaing.campañas.instalador.unshift(campaña)
                  } else if (!campaña.is_op && campaña.origen === 'cliente') {
                    comboCampaing.campañas.cliente.push(campaña)
                  } else if (!campaña.is_op && campaña.origen === 'instalador') {
                    comboCampaing.campañas.instalador.push(campaña)
                  }
                }
              }
            }
          })
        })
        dispatch({
          type: publicOffersActions.GET_PUBLICOFFERS_DATA,
          payload: {
            resumeFields,
            campaingsOnHisMarkets,
            generalConditions,
            generalConditionsDocumentId,
          },
        })
        dispatch({
          type: publicOffersActions.SET_ISLOADING,
          payload: false,
        })
      })
      .catch((error) => console.error(error))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const generalConditionsToogle = useCallback((payload) => {
    dispatch({
      type: publicOffersActions.TOOGLE_GENERALCONDITIONS,
      payload: payload,
    })
  }, [])

  const tooglePublicOffers = useCallback((payload) => {
    dispatch({
      type: publicOffersActions.TOOGLE_PUBLICOFFERS,
      payload: payload,
    })
  }, [])

  const setLoading = useCallback((payload) => {
    dispatch({
      type: publicOffersActions.SET_ISLOADING,
      payload: payload,
    })
  }, [])

  const resetPublicOffers = useCallback(() => {
    dispatch({
      type: publicOffersActions.RESET_OFFERS_TO_SEND,
    })
  }, [])

  return {
    generalConditions: state.generalConditions,
    publicOffer: state.publicOffer,
    publicOffersToSend: state.publicOffersToSend,
    isLoading: state.isLoading,
    generalConditionsDocumentId: state.generalConditionsDocumentId,
    tooglePublicOffers,
    publicOfferData,
    generalConditionsToogle,
    setLoading,
    resetPublicOffers,
    resumeFields: state.resumeFields,
  }
}
