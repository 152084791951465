import React from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { useModalContext } from '../../../../ui/modal/contexts'
import { ActionButton } from '../action-button'
import { DocumentsView } from '../documents-view'
import { DocumentsUpload } from '../documents-upload'
import { HeaderRightBtns } from './header-right-btns.component'
import {
  CANT_CREATE_CERTIFICATE_STATES,
  CANT_UPLOAD_DOCS_STATES,
  APP_FINISHED_STATES,
} from '../../constants'
import { NewCertificateUnavailableDialog } from '../dialogs'
import { ReactComponent as NewCertificateIcon } from '../../../../resources/images/icon_new_certificate.svg'
import { ReactComponent as ViewDocumentsIcon } from '../../../../resources/images/icon_view_documents.svg'
import { ReactComponent as UploadDocumentsIcon } from '../../../../resources/images/icon_upload_documents.svg'
import { RequestGasPComponent } from '../request-gas_p'

export const HeaderButtons = ({
  application,
  setApplication,
  postGASP,
  putGASP,
  getAppById,
  forceRefresh,
  setForceRefresh,
}) => {
  const { formatMessage: fm } = useIntl()
  const history = useHistory()
  const { appId } = useParams()
  const {
    actions: { open },
  } = useModalContext()

  const { role } = useSelector((state) => state.global)

  const getApplicationlWithActiveBudget = (application) => {
    const { presupuesto_exterior: presupuesto, ...restApplication } = application
    return {
      ...restApplication,
      presupuesto: presupuesto,
    }
  }

  const headerButtonsConfig = () => [
    {
      id: 'newCertificate',
      label: fm({ id: 'pages.application.detail.actionButtons.newCertificate.label' }),
      icon: () => <NewCertificateIcon />,
      hidden: application.estado === '10' ? true : false,
      onClick: () => {
        if (
          !(
            (CANT_CREATE_CERTIFICATE_STATES[application.estado] &&
              application.camino_solicitud === '01') ||
            APP_FINISHED_STATES[application.estado] ||
            (application['gran_consumo_id'] &&
              application['gran_consumo']['ubi_equipo_medida'] === 'Interior' &&
              !application['d_ACIF'])
          ) ||
          (application.estado === '05' && application.pasar_contratable)
        ) {
          history.push(`/applications/${appId}/certificates`)
        } else {
          open({
            Component: NewCertificateUnavailableDialog,
            type: 'responsive',
            maxWidth: 'sm',
            data: { application },
          })
        }
      },
    },
    {
      id: 'viewDocuments',
      label: fm({ id: 'pages.application.detail.actionButtons.viewDocuments.label' }),
      icon: () => <ViewDocumentsIcon />,
      hidden: false,
      onClick: () =>
        open({
          Component: DocumentsView,
          data: { model: getApplicationlWithActiveBudget(application), setApplication },
          type: 'responsive',
          maxWidth: 'md',
        }),
    },
    {
      id: 'uploadDocuments',
      label: fm({ id: 'pages.application.detail.actionButtons.uploadDocuments.label' }),
      icon: () => <UploadDocumentsIcon />,
      hidden: CANT_UPLOAD_DOCS_STATES[application.estado],
      onClick: () =>
        open({
          Component: DocumentsUpload,
          data: { application: getApplicationlWithActiveBudget(application), setApplication },
          type: 'responsive',
          maxWidth: 'sm',
        }),
    },
    {
      id: 'request_gas_p',
      label: application.gas_p
        ? fm({ id: 'pages.application.detail.actionButtons.modify_request_gas_p.label' })
        : fm({ id: 'pages.application.detail.actionButtons.request_gas_p.label' }),
      icon: () => <UploadDocumentsIcon />,
      hidden:
        CANT_UPLOAD_DOCS_STATES[application.estado] ||
        !application.gran_consumo_id ||
        (application.subtipo_solicitud === 'pdte_normalizar_direccion' &&
          application.gas_p == null),
      onClick: () =>
        open({
          Component: RequestGasPComponent,
          data: {
            application: getApplicationlWithActiveBudget(application),
            postGASP,
            putGASP,
            getAppById,
            forceRefresh,
            setForceRefresh,
          },
          type: 'responsive',
          maxWidth: 'sm',
        }),
    },
  ]

  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" flexGrow={2} flexWrap="wrap" mb={1}>
        {headerButtonsConfig(application, role).map((button, i) => {
          return !button.hidden ? <ActionButton key={i} {...button} /> : null
        })}
      </Box>
      <Box mb={1}>
        <HeaderRightBtns {...{ application, setApplication }} />
      </Box>
    </Box>
  )
}
