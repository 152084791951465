import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Grid, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useCombos } from '../../../combos'
import { useSignupFormContext } from '../../contexts'
import { TextInput, SelectInput } from '../../../../ui'
import { WizardView } from '../wizard'
import { COMPANY_FORM_CONFIG } from './constants'
import { isValidCIF, isValidNIF, isValidNIE } from '../../../../core/utils/idDocValidation'
import { validate } from 'email-validator'
import { GuildCompanyForm } from './guild-company-form'
import { MarketerCompanyForm } from './marketer-company-form'
import { InstallerCompanyForm } from './installer-company-form'

export const CompanyFormComponent = () => {
  const intl = useIntl()
  const combos = useCombos([])
  const { formState, onChange, onSubmit, isLoading } = useSignupFormContext()
  const { installerWorks } = useSignupFormContext()
  const inputProps = { formState, onChange, combos, intl }

  const [errorMssg, setErrorMssg] = useState('')

  const handleNext = ({ step, setStep }) => {
    if (!installerWorks.catchment && !installerWorks.ip) {
      setErrorMssg('Selecciona, al menos, una actividad')
    } else if (
      formState['tipo_actividad'] === '01' &&
      installerWorks.catchment &&
      !formState['registro_industrial']
    ) {
      setErrorMssg('Es obligatorio añadir un registro industrial')
    } else if (
      formState['tipo_actividad'] !== '03' &&
      installerWorks.ip &&
      formState['iban'] &&
      !formState['certificado_iban']
    ) {
      setErrorMssg('Si has informado un IBAN, debes añadir un Certificado IBAN')
    } else if (
      formState['tipo_actividad'] !== '03' &&
      installerWorks.ip &&
      !formState['iban'] &&
      formState['certificado_iban']
    ) {
      setErrorMssg('Si has añadido un Certificado IBAN, debes informar el IBAN')
    } else if (
      !isValidNIF(formState['cif']) &&
      !isValidCIF(formState['cif']) &&
      !isValidNIE(formState['cif'])
    ) {
      setErrorMssg('Por favor, escribe un NIF/NIE/CIF válido')
    } else if (
      !formState['cod_sap'] &&
      // ((formState['tipo_actividad'] === '01' && installerWorks.catchment) ||
      formState['tipo_actividad'] === '02' //)
    ) {
      setErrorMssg(
        'El registro de empresas sólo se permite a aquellos identificados por el sistema de Negdia'
      )
    } else if (!validate(formState['email'])) {
      setErrorMssg('El email suministrado no tiene el formato correcto')
    } else {
      if (
        formState['tipo_actividad'] === '01' &&
        ((installerWorks.catchment && formState.publica === '01') || installerWorks.ip)
      )
        setStep(step + 1)
      else onSubmit()
    }
  }

  return (
    <WizardView title="Datos de la empresa" onNext={handleNext} isLoading={isLoading}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).NOMBRE }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).ACTIVIDAD }} />
        </Grid>
        {formState['tipo_actividad'] === '02' ? <GuildCompanyForm /> : null}
        {formState['tipo_actividad'] === '03' ? <MarketerCompanyForm /> : null}
        {formState['tipo_actividad'] === '01' ? <InstallerCompanyForm /> : null}
      </Grid>
      {errorMssg && (
        <Box mt={2} mb={1}>
          <Alert severity="error">{errorMssg}</Alert>
        </Box>
      )}
    </WizardView>
  )
}
