import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { withStyles, Menu, MenuList, MenuItem, IconButton, Drawer, Box } from '@material-ui/core'
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined'
import CloseIcon from '@material-ui/icons/Close'

import { feedback } from '../../../core/feedback'
import { useAjaxContext } from '../../../core/ajax'
import { menuStyles } from './menu.styles'

const { REACT_APP_WP_URL } = process.env

export const MenuComponent = withStyles(menuStyles)(
  ({ classes, items, isMobileWidth, mobileView }) => {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const { post } = useAjaxContext()
    const userId = useSelector((state) => state.global.user_id)

    const [drawerOpen, setDrawerOpen] = useState(false)

    const createNewApp = () => {
      return post(`/instalador/generate_one_use_link/${userId}`)
        .then(({ data }) => window.open(`${REACT_APP_WP_URL}?root=2&id=${data.message}`))
        .catch(() => feedback('error', formatMessage({ id: 'pages.applications.feedback.1' })))
    }

    const handleClickMenuItem = (item) => {
      setDrawerOpen(!drawerOpen)
      if (item.doc) {
        window.open(item.doc)
      } else if (item.url) {
        if (item.url === 'wp') createNewApp()
        else window.open(item.url)
      } else if (item.path) {
        history.push(item.path)
      }
    }

    const toggleDrawer = (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
      setDrawerOpen(!drawerOpen)
    }

    const MenuItemCollapsable = ({ item }) => {
      const [anchorEl, setAnchorEl] = React.useState(null)

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
      }

      const handleClose = () => {
        setAnchorEl(null)
      }

      return (
        <>
          <MenuItem
            classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
            selected={item.children.some((item) => item.path === history.location.pathname)}
            onClick={handleClick}
          >
            {item.label}
          </MenuItem>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {/* div workaround to avoid console error --> https://github.com/mui/material-ui/issues/15903 */}
            <div>
              <MenuItems items={item.children} />
            </div>
          </Menu>
        </>
      )
    }

    const MenuItems = ({ items }) =>
      items.map((item, i) => (
        <MenuItem
          key={i}
          selected={item.path === history.location.pathname}
          onClick={() => handleClickMenuItem(item)}
          className={classes.menuItem}
        >
          {item.label}
        </MenuItem>
      ))

    return isMobileWidth || mobileView ? (
      <>
        <IconButton onClick={toggleDrawer}>
          <MenuOutlinedIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
          <Box p={2}>
            <Box mb={1}>
              <IconButton onClick={toggleDrawer}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Box>
            {items.map((item, i) => (
              <Box key={i}>
                {item.children && item.children.length ? (
                  <Box key={i}>
                    <Box key={i} pb={1} fontSize={20}>
                      <MenuItem
                        classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                        selected={item.children.some(
                          (item) => item.path === history.location.pathname
                        )}
                      >
                        {item.label}
                      </MenuItem>
                    </Box>
                    <Box ml={4}>
                      <MenuItems items={item.children} />
                    </Box>
                  </Box>
                ) : (
                  <MenuItem
                    key={i}
                    selected={item.path === history.location.pathname}
                    onClick={() => handleClickMenuItem(item)}
                    classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
                  >
                    {item.label}
                  </MenuItem>
                )}
              </Box>
            ))}
          </Box>
        </Drawer>
      </>
    ) : (
      <MenuList className={classes.horizontal}>
        {items.map((item, i) => (
          <Box key={i}>
            {item.children && item.children.length ? (
              <MenuItemCollapsable item={item} />
            ) : (
              <MenuItem
                key={i}
                selected={item.path === history.location.pathname}
                onClick={() => handleClickMenuItem(item)}
                classes={{ root: classes.menuItem, selected: classes.selectedMenuItem }}
              >
                {item.label}
              </MenuItem>
            )}
          </Box>
        ))}
      </MenuList>
    )
  }
)
